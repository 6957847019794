@import "../../../theme/index.module.scss";

.Header {
  width: 100%;
  height: 4.75em;
  top: 0;
  z-index: 4;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed;
  background: white;

  @include bp($medium) {
    overflow: unset;
  }
}

.HeaderContent {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: space-between;
}

.Logo {
  padding: 0;
  height: auto;
  border: none;
  z-index: 4;
  width: 13em;
  display: flex;

  img {
    width: 100% !important;
    height: auto !important;
    object-fit: contain;
  }

  @include bp($medium) {
    width: 18em;
  }

  @include bp($larger) {
    margin-left: 4em;
  }
}

.HeaderContainer {
  display: flex;
  align-items: baseline;

  @include bp($larger) {
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 4em;
  }
}
